import Vue from 'vue'
import Router from 'vue-router'
import routes from './routes'

Vue.use(Router)
const _router = new Router({
    mode: 'history',//这个是让路由变得更漂亮，没有#号，但是需要后端配置东西才能访问
    base: process.env.BASE_URL,
    routes,
});
// const routerPush = Router.prototype.push
// Router.prototype.push = function push(location:any) {
//   return routerPush.call(this, location).catch((error:any)=> error)
// }
export default _router
