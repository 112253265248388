const URL =
  process.env.NODE_ENV === 'production'
    ? process.env.VUE_APP_FLAG==='pro'? 'https://donglv.donkeycn.com':'https://dev.donkeycn.com'
    : 'https://dev.donkeycn.com'

    // process.env.NODE_ENV === 'production'
    // ? 'https://donglv.donkeycn.com'
    // : 'https://donglv.donkeycn.com'


// const URL =
//   process.env.NODE_ENV === 'production'
//     ? 'http://211.149.182.192:90'
//     : 'http://211.149.182.192:90'

// const URL =
//   process.env.NODE_ENV === 'production'
//     ? 'https://donglv.donkeycn.com'
//     : 'https://donglv.donkeycn.com'
export default URL
