import Vue from 'vue';
import App from './App.vue';
import Component from 'vue-class-component';  //vue对ts支持的装饰器
import router from './router/index';
import store from './store';
import './registerServiceWorker';
import Mixins from './mixins'
import '@/assets/css/public.less';
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './libs/dateformat'
import Ajax from './libs/ajax'
import echarts from 'echarts'
import '../src/assets/icon/iconfont.css'

Vue.use(ElementUI)
Vue.mixin(Mixins);//全局混入 里面有一些变量 或者方法。再以后每创建一个vue实例的时候 都会有这些属性或者方法

//需要注册路由事件，不然不会触发   注册钩子
Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteLeave',
  'beforeRouteUpdate',
]);


Vue.config.productionTip = false;

if(process.env.NODE_ENV==='production'){
    if(process.env.VUE_APP_FLAG==='pro'){
        console.log('线上环境')
    }else{
        console.log('测试环境')
    }
}else{
    console.log('本地环境')
}
Vue.prototype.$ajax = Ajax;  //在Vue原型上注册方法
Vue.prototype.$echarts=echarts



new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
