import { remoteServiceBaseUrl } from './appconst'
import Ajax from './ajax'
class Utils {
  private userTokenCacheKey: string = 'as_admin_token'  //私有变量 token的key
  private userToken!: string | null  //token
  constructor () {   //构造函数
    this.userToken = localStorage.getItem(this.userTokenCacheKey)
  }
  setUserToken (token: string, remember: boolean) {  //设置token
    this.userToken = token
    if (remember) localStorage.setItem(this.userTokenCacheKey, token)
  }
  getUserToken () { //获取token
    return this.userToken
  }
  clearUserToken () { //清除token
    this.userToken = ''
    localStorage.removeItem(this.userTokenCacheKey)
  }

  isType (obj: any, type: string) { //  判断类型 传两个值
    if (typeof obj !== 'object') return false
    // 判断数据类型的经典方法：
    const typeString = Object.prototype.toString.call(obj)
    let flag
    switch (type) {
      case 'Array':
        flag = typeString === '[object Array]'
        break
      case 'Date':
        flag = typeString === '[object Date]'
        break
      case 'RegExp':
        flag = typeString === '[object RegExp]'
        break
      default:
        flag = false
    }
    return flag
  }
  /**
   * deep clone
   * @param  {[type]} parent object 需要进行克隆的对象
   * @return {[type]}        深克隆后的对象
   */
  clone (parent: any) {
    // 维护两个储存循环引用的数组
    const parents: any[] = []
    const children: any[] = []

    const _clone = (parent: any) => {
      if (parent === null) return null
      if (typeof parent !== 'object') return parent

      let child, proto

      if (this.isType(parent, 'Array')) {
        // 对数组做特殊处理
        child = []
      } else if (this.isType(parent, 'RegExp')) {
        // 对正则对象做特殊处理
        child = new RegExp(parent.source)
        if (parent.lastIndex) child.lastIndex = parent.lastIndex
      } else if (this.isType(parent, 'Date')) {
        // 对Date对象做特殊处理
        child = new Date(parent.getTime())
      } else {
        // 处理对象原型
        proto = Object.getPrototypeOf(parent)
        // 利用Object.create切断原型链
        child = Object.create(proto)
      }

      // 处理循环引用
      const index = parents.indexOf(parent)
      
      if (index != -1) {
        // 如果父数组存在本对象,说明之前已经被引用过,直接返回此对象
        return children[index]
      }
      parents.push(parent)
      children.push(child)

      for (let i in parent) {
        // 递归
        child[i] = _clone(parent[i])
      }

      return child
    }
    return _clone(parent)
  }
}

export default new Utils()
