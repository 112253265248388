






import { Component, Vue, Watch } from "vue-property-decorator";
import { State } from "vuex-class";

@Component
export default class App extends Vue {  
  loaded: boolean = false;
  async created() {
    var res = await this.$ajax.get(
      "/api/services/app/Configuration/GetSystemSettings"
    );
    if (res.data.success) {
      var settings = res.data.result;
      this.$store.commit("setSystemSettings", settings);
      this.loaded = true;
      document.title = `${settings.systemName}后台管理系统`;
    }
  }
}
