import axios from 'axios'
import { remoteServiceBaseUrl } from './appconst'
import Vue from 'vue'
import router from '@/router'
import utils from './utils';
import { Message, MessageBox } from 'element-ui';
axios.defaults.withCredentials = true
const ajax = axios.create({ //自定义配置新建一个 axios 实例
    baseURL: remoteServiceBaseUrl,
    timeout: 30000
});
ajax.interceptors.request.use(function (config) { //在 request 请求拦截器实现
    if (!!utils.getUserToken()) {
        config.headers.common["Authorization"] = "Bearer " + utils.getUserToken(); //有token  就在herder上添加上token
    }
    return config;
}, function (error) {
    return Promise.reject(error);
});
ajax.interceptors.response.use((respon) => {  //响应拦截  主要是错误拦截并处理
    return respon
}, (error) => {
    if (!!error.response && !!error.response.data.error && !!error.response.data.error.message && error.response.data.error.details) {
        MessageBox.alert(error.response.data.error.message, error.response.data.error.details, {
            type: 'warning'
        })
    } else if (!!error.response && !!error.response.data.error && !!error.response.data.error.message) {
        Message.error(error.response.data.error.message)
    } else if (!error.response) {
        Message.error('网络错误');
    }
    if (error.response && error.response.status == 401) {
        utils.clearUserToken();
        router.push({ path: '/login' })
    }
    return Promise.reject(error);
})
export default ajax;