export default [
  {
    path: '/login',
    name: 'adminLogin',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/',
    component: () => import('../views/Admin.vue'),
    children: [
      {
        path: '/',
        name: '首页',
        component: () => import('../views/admin/Dashboard.vue')
      },
      {
        path: '/system/loginlogs',
        name: '登录日志',
        component: () => import('../views/admin/system/LoginLogs.vue')
      },
      {
        path: '/system/handlelogs',
        name: '操作日志',
        component: () => import('../views/admin/system/HandleLogs.vue')
      },
      {
        path: '/system/roles',
        name: '角色管理',
        component: () => import('../views/admin/system/Roles.vue')
      },
      {
        path: '/system/users',
        name: '用户管理',
        component: () => import('../views/admin/system/Users.vue')
      },
      {
        path: '/order/list',
        name: '租赁订单',
        component: () => import('../views/admin/order/List.vue')
      },
      {
        path: '/order/feedbacks',
        name: '反馈列表',
        component: () => import('../views/admin/order/Feedbacks.vue')
      },
      {
        path: '/order/details',
        name: '订单详细',
        component: () => import('../views/admin/order/Details.vue')
      },
      {
        path: '/device/mallorders',
        name: '商城订单',
        component: () => import('../views/admin/order/MallOrders.vue')
      },
      {
        path: '/device/mallorder/details',
        name: '商城订单详细',
        component: () => import('../views/admin/order/MallOrderDetails.vue')
      },
      {
        path: '/article/list',
        name: '文章列表',
        component: () => import('../views/admin/article/List.vue')
      },
      {
        path: '/article/edit',
        name: '添加/修改文章',
        component: () => import('../views/admin/article/Edit.vue')
      },
      {
        path: '/device/list',
        name: '设备管理',
        component: () => import('../views/admin/device/List.vue')
      },
      {
        path: '/device/details',
        name: '设备详细',
        component: () => import('../views/admin/device/Details.vue')
      },
      {
        path: '/device/types',
        name: '型号管理',
        component: () => import('../views/admin/device/Types.vue')
      },
      {
        path: '/device/terminals',
        name: '终端管理',
        component: () => import('../views/admin/device/Terminals.vue')
      },
      {
        path: '/device/terminallogs',
        name: '充电宝日志',
        component: () => import('../views/admin/device/TerminalLogs.vue')
      },
      {
        path: '/device/type/edit',
        name: '添加/修改设备',
        component: () => import('../views/admin/device/EditType.vue')
      },
      {
        path: '/agent/list',
        name: '代理列表',
        component: () => import('../views/admin/agent/List.vue')
      },
      {
        path: '/agent/details',
        name: '代理详细',
        component: () => import('../views/admin/agent/Details.vue')
      },
      {
        path: '/merchant/list',
        name: '商家列表',
        component: () => import('../views/admin/merchant/List.vue')
      },
      {
        path: '/merchant/details',
        name: '商家详细',
        component: () => import('../views/admin/merchant/Details.vue')
      },
      {
        path: '/merchant/categorys',
        name: '商家类别',
        component: () => import('../views/admin/merchant/Categorys.vue')
      },
      {
        path: '/member/list',
        name: '会员列表',
        component: () => import('../views/admin/member/List.vue')
      },
      {
        path: '/member/capitallogs',
        name: '余额日志',
        component: () => import('../views/admin/member/CapitalLogs.vue')
      },
      {
        path: '/member/details',
        name: '会员详细',
        component: () => import('../views/admin/member/Details.vue')
      },
      {
        path:'/member/freezelogs',
        name:'冻结日志',
        component:()=>import('../views/admin/member/FreezeLogs.vue')
      },
      {
        path: '/finance/cash/list',
        name: '提现管理',
        component: () => import('../views/admin/finance/Cashs.vue')
      },
      {
        path: '/system/settings',
        name: '系统配置',
        component: () => import('../views/admin/system/Settings.vue')
      },
      {
        path: '/system/appnotification',
        name: 'APP通知',
        component: () => import('../views/admin/system/AppNotification.vue')
      },
      {
        path:'/setconfig/list',
        name:'小程序配置',
        component:()=>import('../views/admin/setconfig/List.vue')
      },
      {
        path:'/finance/earningsLogs',
        name:'平台收益',
        component:()=>import('../views/admin/finance/earningsLogs.vue')
      },
      {
        path:'/device/returnFactory',
        name:'返厂记录',
        component:()=>import('../views/admin/device/ReturnFactory.vue')
      },
      {
        path:'/agent/task',
        name:'任务日志',
        component:()=>import('../views/admin/agent/Task.vue')
      },
      {
        path:'/agent/level',
        name:'等级日志',
        component:()=>import('../views/admin/agent/Level.vue')
      },
      {
        path:'/finance/statistics',
        name:'财务统计',
        component:()=>import('../views/admin/finance/Statistics.vue')
      },
      {
        path:'/member/questionback',
        name:'问题反馈',
        component:()=>import('../views/admin/member/QuestionBack.vue')
      },
      {
        path:'/article/classify',
        name:'分类管理',
        component:()=>import('../views/admin/article/classify.vue')
      },
      {
        path:'/merchant/statistics',
        name:'营收统计',
        component:()=>import('../views/admin/merchant/Statistics.vue')
      },
      {
        path:'/advert',
        name:'广告管理',
        component:()=>import('../views/admin/advert/list.vue')
      },
      {
        path:'/agent/record',
        name:'代理档案',
        component:()=>import('../views/admin/agent/record.vue')
      }
    ]
  }
]
