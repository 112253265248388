import { Component, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'
import { remoteServiceBaseUrl } from './libs/appconst'

@Component
export default class Mixins extends Vue {
  // uploadHeaders:any = { 'Authorization': 'bearer '+localStorage.getItem(window.consts.strings.adminToken)}
  defaultPageSize: number[] = [1, 2, 5, 10, 15, 20, 50, 100, 200]   //每个vue中都有的属性
  pagerHeader!: any
  @State('allEnums')
  allEnums!: any
  @State('systemSettings')
  systemSettings!: any
  //所有页面共享的日期范围选择组件的配置项
  defaultPickerOptions: any = {
    shortcuts: [
      {
        text: '最近7天',
        onClick (picker: any) {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 6)
          picker.$emit('pick', [start, end])
        }
      },
      {
        text: '最近30天',
        onClick (picker: any) {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 29)
          picker.$emit('pick', [start, end])
        }
      },
      {
        text: '最近三个月',
        onClick (picker: any) {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 89)
          picker.$emit('pick', [start, end])
        }
      }
    ]
  }
// 组件生命周期加载时
  mounted () {     
    this.setPageHead(this.pagerHeader)
  }
//   keep-alive 中的生命周期
  activated () {
    this.setPageHead(this.pagerHeader)
  }

  setPageHead (data: any) {
    if (data && this.$store) {
      this.$store.commit('setSysPagerHeader', data)
    }
  }
// 设置浏览器头部
  setWindowTitle (title: string) {
    document.title = title ? title + ' -| 动驴管理' : '动驴管理'
  }

  closeCurrTabAndGoToUrl (url: string) {
    this.$emit('closeCurrTab', url)
  }

  hasPermission (permissionName: string) {
    let admin = this.$store.state.admin
    if (
      admin.id &&
      admin.id > 0 &&
      admin.permissions &&
      admin.permissions.indexOf(permissionName) >= 0
    ) {
      return true
    }
    return false
  }
  getFormatDate (strDate: string, format: string = 'yyyy-MM-dd hh:mm:ss') {
    return new Date(strDate).Format(format)
  }
  getEnum (name: string) {
      console.log(this.allEnums);
      
    var enumInfos = this.allEnums[name]
    if (!enumInfos) {
       
      this.$message.warning('未获取到配置')
      throw `未获取到枚举配置 ${name}`
    }
    return enumInfos
  }
  getEnumTitle (name: string, value: number) {
    var enumInfos = this.getEnum(name)
    if (enumInfos) {
      var infoItem = enumInfos.find((m: any) => m.value == value)
      if (!infoItem) {
        this.$message.warning('未获取到枚举值')
        throw `未获取到枚举值 ${name}=>${value}`
      }
      return infoItem.name
    }
  }
  getServerUrl (path: string) {
    return `${remoteServiceBaseUrl}${path}`
  }
  // 执行下拉dropdownCommand,command格式:[function,args]
  dropdownHandleCommand (cmd: any[]) {
    cmd[0](...cmd[1])
  }

  async skipToRoute (path: string) {
    this.$router.push(path)
  }
}
