import Vue from 'vue'
import Vuex from 'vuex'

import utils from '@/libs/utils'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: {
      id: 0,
      uid: 'admin',
      mobile: '',
      email: '',
      lastLogin: ''
    },
    admin: {
      id: 0,
      userName: '',
      name: '',
      emailAddress: '',
      mobile: '',
      lastLogin: '',
      permissions: [],
      tenant: null
    },
    sysPagerHeader: {
      title: '',
      desc: '',
      breadcrumb: [] // 面包屑导航
    },
    allEnums: {},
    systemSettings: null
  },
  mutations: {
    setUser (state, payload) {
      console.log('setUser')
      /*console.log('设置用户信息：')
      console.log(payload)*/
      // 这里的 `state` 对象是模块的局部状态
      state.user = payload
    },
    setAdmin (state, payload) {
      console.log('setAdmin')
      /*console.log('设置管理员信息：')
      console.log(payload)*/
      // 这里的 `state` 对象是模块的局部状态
      state.admin = payload
    },
    userLogout (state) {
      utils.clearUserToken()
      state.user = {
        id: 0,
        uid: '',
        mobile: '',
        email: '',
        lastLogin: ''
      }
    },
    adminLogout (state) {
      utils.clearUserToken()
      state.admin = {
        id: 0,
        userName: '',
        name: '',
        emailAddress: '',
        mobile: '',
        lastLogin: '',
        permissions: [],
        tenant: null
      }
    },
    setSysPagerHeader (state, payload) {
      // console.log(payload)
      state.sysPagerHeader = payload
    },
    setAllEnums (state, payload) {
      state.allEnums = payload
    },
    // 存是标题 在APP调用
    setSystemSettings (state, payload) {
      state.systemSettings = payload
    }
  },
  actions: {
    // this.$store.dispatch('setUser',{}) 触发
    setUser (context, payload) {
      context.commit('setUser', payload)
    },
    setAdmin (context, payload) {
      context.commit('setAdmin', payload)
    },
    userLogout (context) {
      context.commit('userLogout')
    },
    adminLogout (context) {
      context.commit('adminLogout')
    }
  },
  getters: {
    adminPermissions: state => {
      return state.admin.permissions
    }
  }
})
